.category-btn,
.search-btn,
.search-input,
.menu-btn,
.navbar__setting-notif,
.navbar__profile {
    background-color: transparent;
    border: none;
    outline: none;
    /* font-family: var(--font-default); */
}

.navbar__container,
.search-box {
    display: flex;
}

.category-btn,
.search-input {
    font-size: 14px;
}

.navbar-search {
    flex: 0.6;
}

.search-input {
    flex: 1;
}


/* Lipstick style */

.navbar {
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 6px -1px;
}

.navbar.bg-white {
    background-color: var(--white);
}

.navbar__container {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar__top {
    align-items: center;
}

.navbar-nav {
    margin-right: 24px;
}

.navbar-search {
    background-color: #f4f4f4;
    color: var(--gray);
    padding: 0.4em;
    border-radius: 8px;
}

.category-btn {
    color: var(--black);
    text-decoration: none;
}

.search-btn,
.navbar__setting-notif {
    transition: 0.2s all ease;
}

.search-input {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.navbar__setting-notif:hover,
.search-btn:hover {
    background-color: #f4f4f4;
}

.navbar__setting {
    flex: 0.4;
    align-items: center;
    justify-content: flex-end;
}

.navbar__setting-notif {
    padding: 2px 8px;
    position: relative;
    font-size: 18px;
    border-radius: 8px;
}

.navbar__profile {
    align-items: center;
}

.navbar__avatar-frame {
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-left: 16px;
    height: 28px;
    width: 28px;
    background-color: #f4f4f4;
    border-radius: 50%;
    transition: 0.5s all ease;
    overflow: hidden;
}

.navbar__avatar-frame img {
    width: 100%;
}

.navbar__profile-name {
    font-size: 12px;
}

.navbar__profile:hover .navbar__avatar-frame {
    box-shadow: 0 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.notification-bullet {
    position: absolute;
    top: 2px;
    right: 4px;
    height: 12px;
    width: 12px;
    background-color: var(--red);
    border: 2px solid var(--white);
    border-radius: 50%;
}

.t-btn-danger {
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.t-btn-danger-outlined {
    color: var(--red);
    background-color: var(--white);
    border-color: var(--red);
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.slide-top {
    -webkit-animation: slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}


/* Breakpoints */

@media only screen and (max-width: 540px) {
    .navbar {
        padding: 0;
    }
    .navbar-brand {
        /* flex: 1.5; */
        /* max-width: 55%; */
        /* margin-right: 0; */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .navbar-brand img {
        /* width: 100%; */
        max-width: 100%;
        max-height: 100%;
    }
    .navbar__container:not(.navbar__small) {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        flex-direction: column;
        align-items: stretch;
    }
    .navbar__top {
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    .navbar__top>*:not(.navbar-brand) {
        flex: 1;
    }
    .navbar-nav {
        margin-right: 0;
        transition: 0.2s all ease;
    }
    .menu-btn {
        padding: 0;
        /* color: var(--red); */
        transition: 0.2s all ease;
    }
    .navbar-search,
    .search-input {
        flex: 1 !important;
    }
    .menu-btn-icon {
        font-size: 20px;
    }
    .menu-btn.mobile-notification {
        position: relative;
        font-size: 22px;
    }
    .menu-btn:active {
        opacity: 0.5;
    }
    .navbar-xs--left {
        justify-content: flex-start;
    }
    .navbar-xs--right {
        justify-content: flex-end;
    }
    .notification-bullet {
        right: -4px !important;
    }
}


/* Reusable class */


/* Text format */

.show-xs {
    display: none !important
}

.show-xs.d-flex {
    display: none !important
}


/* Breakpoints */

@media only screen and (max-width: 540px) {
    .xs-hidden {
        display: none !important
    }
    .xl-hidden {
        display: none !important
    }
    .show-xs {
        display: block !important
    }
    .show-xs.d-flex {
        display: flex !important
    }
    .xs-hidden.d-flex {
        display: none !important
    }
}

@media only screen and (min-width: 541px) {
    .navbar-nav {
        display: none !important
    }
}

@media only screen and (max-width: 991px) {
    .xs-hidden {
        display: none !important
    }
    .footer-show {
        display: none !important
    }
}

@media only screen and (min-width: 992px) {
    .xl-hidden {
        display: none !important;
    }
    .footer-hidden {
        display: none !important
    }
}

.nav-tabs .nav-link.active {
    border-color: transparent;
    border-bottom: 1px solid #dee2e6;
}