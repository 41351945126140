@media screen and (max-width: 768px) {
    .title-h1 {
        font-size: 21px !important;
        line-height: 30px !important;
    }

    .flex-mobile {
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
    .flex-mobile>.col-sm-6:first-child {
        margin-left: 7px;
    }
}

@media screen and (max-width: 540px) {
    .title-h1 {
        font-size: 18px !important;
        line-height: 27px !important;
    }
    .title-h2 {
        font-size: 18px !important;
        line-height: 27px !important;
    }
    .subtitle-h2 {
        font-size: 12px !important;
        line-height: 18px !important;
    }
    .see-more {
        font-size: 14px !important;
        line-height: 21px !important;
    }
    .flex-mobile {
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
    .flex-mobile>.col-6:first-child {
        margin-left: 7px;
    }
    .col-6 {
        max-width: 46%;
    }
    .flex-mobile>.col-12:first-child {
        margin-left: 7px;
    }
    .max-width-title-mobile {
        max-width: 72%;
    }
}

body {
    font-family: 'Rubik', sans-serif;
}

.start-content {
    padding-top: 64px;
}

.section:first-child {
    padding-top: 0px;
    padding-bottom: 24px;
}

.section {
    padding-top: 24px;
    padding-bottom: 24px;
}

.section:last-child {
    padding-top: 24px;
    padding-bottom: 0px;
}

.title-h1 {
    /* font-family: poppins, sans-serif; */
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--dark);
}

.title-h2 {
    font-family: 'Barlow', sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--dark);
}

.subtitle-h2 {
    font-family: 'Barlow', sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: var(--gray);
}

.see-more {
    color: var(--danger);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.flex-mobile {
    display: flex;
}

a {
    color: var(--danger);
}

.bs-default {
    box-shadow: 0 1px 6px 0 rgba(49, 53, 59, .12);
}

.margin-grid {
    margin-left: -8px;
    margin-right: -8px;
}

.padding-grid {
    padding: 8px;
}


/* font */

.fs-12px {
    font-size: 12px;
    line-height: 18px;
}

.fs-14px {
    font-size: 14px;
    line-height: 21px;
}


/* margin x */

.mx-4px {
    margin-left: 4px;
    margin-right: 4px;
}

.mx-8px {
    margin-left: 8px;
    margin-right: 8px;
}

.mx-16px {
    margin-left: 16px;
    margin-right: 16px;
}

.mx-24px {
    margin-left: 24px;
    margin-right: 24px;
}

.mx-32px {
    margin-left: 32px;
    margin-right: 32px;
}


/* margin y */

.my-4px {
    margin-top: 4px;
    margin-bottom: 4px;
}

.my-8px {
    margin-top: 8px;
    margin-bottom: 8px;
}

.my-16px {
    margin-top: 16px;
    margin-bottom: 16px;
}

.my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
}

.my-32px {
    margin-top: 32px;
    margin-bottom: 32px;
}


/* margin */

.m-4px {
    margin: 4px;
}

.m-8px {
    margin: 8px;
}

.m-16px {
    margin: 16px;
}

.m-24px {
    margin: 24px;
}

.m-32px {
    margin: 32px;
}


/* margin top */

.mt-3px {
    margin-top: 3px;
}

.mt-4px {
    margin-top: 4px;
}

.mt-8px {
    margin-top: 8px;
}

.mt-16px {
    margin-top: 16px;
}

.mt-24px {
    margin-top: 24px;
}

.mt-32px {
    margin-top: 32px;
}


/* margin right */

.mr-4px {
    margin-right: 4px;
}

.mr-8px {
    margin-right: 8px;
}

.mr-16px {
    margin-right: 16px;
}

.mr-24px {
    margin-right: 24px;
}

.mr-32px {
    margin-right: 32px;
}

.-mr-30px {
    margin-right: -30px;
}


/* margin bottom */

.mb-4px {
    margin-bottom: 4px;
}

.mb-8px {
    margin-bottom: 8px;
}

.mb-16px {
    margin-bottom: 16px;
}

.mb-24px {
    margin-bottom: 24px;
}

.mb-32px {
    margin-bottom: 32px;
}


/* margin left */

.ml-4px {
    margin-left: 4px;
}

.ml-8px {
    margin-left: 8px;
}

.ml-16px {
    margin-left: 16px;
}

.ml-24px {
    margin-left: 24px;
}

.ml-32px {
    margin-left: 32px;
}


/* padding x */

.px-4px {
    padding-left: 4px;
    padding-right: 4px;
}

.px-8px {
    padding-left: 8px;
    padding-right: 8px;
}

.px-16px {
    padding-left: 16px;
    padding-right: 16px;
}

.px-24px {
    padding-left: 24px;
    padding-right: 24px;
}

.px-32px {
    padding-left: 32px;
    padding-right: 32px;
}


/* padding y */

.py-4px {
    padding-top: 4px;
    padding-bottom: 4px;
}

.py-8px {
    padding-top: 8px;
    padding-bottom: 8px;
}

.py-16px {
    padding-top: 16px;
    padding-bottom: 16px;
}

.py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
}

.py-32px {
    padding-top: 32px;
    padding-bottom: 32px;
}


/* padding top */

.p-4px {
    padding: 4px;
}

.p-8px {
    padding: 8px;
}

.p-16px {
    padding: 16px;
}

.p-24px {
    padding: 24px;
}

.p-32px {
    padding: 32px;
}


/* padding top */

.pt-4px {
    padding-top: 4px;
}

.pt-8px {
    padding-top: 8px;
}

.pt-16px {
    padding-top: 16px;
}

.pt-24px {
    padding-top: 24px;
}

.pt-32px {
    padding-top: 32px;
}


/* padding right */

.pr-4px {
    padding-right: 4px;
}

.pr-8px {
    padding-right: 8px;
}

.pr-16px {
    padding-right: 16px;
}

.pr-24px {
    padding-right: 24px;
}

.pr-32px {
    padding-right: 32px;
}


/* padding bottom */

.pb-4px {
    padding-bottom: 4px;
}

.pb-8px {
    padding-bottom: 8px;
}

.pb-16px {
    padding-bottom: 16px;
}

.pb-24px {
    padding-bottom: 24px;
}

.pb-32px {
    padding-bottom: 32px;
}


/* padding left */

.pl-4px {
    padding-left: 4px;
}

.pl-8px {
    padding-left: 8px;
}

.pl-16px {
    padding-left: 16px;
}

.pl-24px {
    padding-left: 24px;
}

.pl-32px {
    padding-left: 32px;
}


/* border radius */

.br-4px {
    border-radius: 4px;
}

.br-8px {
    border-radius: 8px;
}

.br-16px {
    border-radius: 16px;
}

.br-24px {
    border-radius: 24px;
}

.br-32px {
    border-radius: 32px;
}