@media screen and (max-width: 768px) {
    .icon-feature {
        height: 76px !important;
        width: 76px !important;
    }

    .title-category {
        font-size: 14px !important;
        line-height: 20px !important;
        margin-top: 7px !important;
    }
}

@media screen and (max-width: 540px) {
    .title-category {
        font-size: 12px !important;
        line-height: 15px !important;
        margin-top: 4px !important;
    }
    .badge-new {
        font-size: 12px !important;
        line-height: 32px !important;
        height: 32px !important;
        width: 32px !important;
    }
    .badge-category {
        font-size: 10px !important;
        line-height: 15px !important;
    }
    .title-recipe {
        font-size: 14px !important;
        line-height: 21px !important;
        margin-top: 4px !important;
    }
    .icon-feature {
        height: 64px !important;
        width: 64px !important;
    }
    .estimate {
        font-size: 12px !important;
        line-height: 18px !important;
        margin-top: 4px !important;
    }
    .user-name {
        font-size: 12px !important;
        line-height: 18px !important;
        margin-top: 4px !important;
    }
}

a:hover {
    text-decoration: none;
}

.card-grid {
    position: relative;
    background-color: var(--white);
}

.card-grid>a {
    color: var(--dark);
}

.card-grid>a:hover {
    color: var(--danger);
}

.card-grid>a>div>img:hover {
    box-shadow: 0 10px 20px 0 rgba(53, 64, 90, .2);
}

.icon-feature {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background-color: #e4e4e4;
    margin: 0 auto;
}

.header-content {
    position: relative;
}

.title-category {
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    overflow: hidden;
    /* font-family: roboto, sans-serif; */
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 24px;
    text-align: center;
}

.badge-new {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: var(--red);
    color: #ffffff;
    border-radius: 100%;
    font-size: 14px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    height: 40px;
    width: 40px;
}

.badge-category {
    color: var(--danger);
    background-color: #feecf0;
    margin-top: 8px;
    font-weight: 500;
}

.title-recipe {
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    overflow: hidden;
    /* font-family: poppins, sans-serif; */
    color: var(--dark);
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 27px;
}

.estimate {
    font-size: 14px;
    line-height: 21px;
    margin-top: 8px;
    color: var(--gray);
}

.user-name {
    font-size: 14px;
    line-height: 21px;
    margin-top: 8px;
    color: var(--gray);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}