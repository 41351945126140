.wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: transparent;
    transition: 0.5s all ease;
    z-index: 999;
}

.drawer {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 3;
    background-color: var(--white);
    transform: translateX(-100%);
    transition: 0.5s all ease;
}

.overlay {
    flex: 1;
}

.drawer__top-bar {
    padding: 0.5em 1em;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f4f4f4;
}

.drawer__top-bar-label {
    font-size: 18px;
    font-weight: 600;
}

.drawer__top-bar-close {
    padding: 0;
    font-size: 22px;
    background-color: transparent;
    color: var(--black);
    border: none;
    outline: none;
    transition: 0.2s all ease;
}

.drawer__top-bar-close:active {
    opacity: 0.5;
}

.drawer__container {
    flex: 1;
    display: block;
    padding-bottom: 4rem;
    overflow-y: auto;
}

.drawer__content-box {
    padding: 16px 0;
    width: 100%;
    border-bottom: 2px solid #f4f4f4;
}

.drawer__content-box:last-child {
    border-bottom: none;
}

.drawer__item-header,
.drawer__item {
    display: flex;
    align-items: center;
}

.drawer__item-header {
    justify-content: space-between;
}

.drawer__item-body {
    padding-top: 0.5em;
}

.drawer__item {
    color: var(--black);
    transition: 0.2s opacity ease;
}

.drawer__item--list {
    margin-top: 8px;
    margin-bottom: 16px;
}

.drawer__item--list.item-list--icon {
    margin-bottom: 0.5em;
}

.drawer__item--list:last-child {
    margin-bottom: 0;
}

.drawer__item-icon {
    margin-right: 0.5em;
    width: 25px;
    font-size: 22px;
    text-align: center;
}

.drawer__item--list .drawer__item-icon {
    margin-right: 8px;
    font-size: 16px;
}

.drawer__item-icon.item-icon--social {
    font-size: 22px;
}

.drawer__item-label {
    font-weight: 400;
}

.drawer__item-label a {
    color: var(--black);
}

.drawer__item--list .drawer__item-label {
    font-size: 14px;
}

.drawer__item-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    background-color: #f4f4f4;
    color: var(--black);
    font-size: 22px;
    border: none;
    border-radius: 50%;
    outline: none;
    transition: 0.2s all ease;
}

.label--light {
    font-weight: 400;
}

.overlay {
    background-color: transparent;
}

.wrapper.active {
    background-color: rgba(0, 0, 0, 0.5);
}

.wrapper.active .drawer {
    transform: translateX(0);
}

.drawer__item-toggler:hover,
.drawer__item:not(.no-effect):hover {
    text-decoration: none;
    opacity: 0.5;
}

.text-bold {
    font-weight: 600;
}

@media only screen and (min-width: 540px) {
    .wrapper.active {
        display: none !important;
    }
}