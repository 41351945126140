@media screen and (max-width: 540px) {
  .card-main-feature {
      padding: 8px 16px !important;
  }
  .start-home {
      padding-top: 104px !important;
  }
}

.start-home {
  padding-top: 64px;
  padding-bottom: 80px;
  background-image: url('../assets//background-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.start-home-sub {
  padding-top: 64px;
  padding-bottom: 80px;
  background: none!important;
}

.start-home-content {
  margin-top: -64px;
}

.start-home-sub {
  padding-top: 64px;
  padding-bottom: 80px;
  background: none!important;
}

.card-main-feature {
  padding: 32px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(53, 64, 90, .2);
}

.content-main-feature {
  max-width: 720px;
  margin: 0 auto;
}

footer{
  border-top: 2px solid var(--orange);
  background-color: #f8f8f8;
}

.icon.is-medium {
  height: 2rem;
  width: 2rem;
}

.font-12{
  font-size: 12px!important;
}

.font-13{
  font-size: 13px!important;
}

.font-14{
  font-size: 14px!important;
}

.font-15{
  font-size: 15px!important;
}

.font-16{
  font-size: 16px!important;
}

.font-18{
  font-size: 18px!important;
}

.font-20{
  font-size: 20px!important;
}

.font-22{
  font-size: 22px!important;
}

.font-24{
  font-size: 24px!important;
}

.font-26{
  font-size: 26px!important;
}

.font-weight-100{
  font-weight: 100!important;
}

.font-weight-200{
  font-weight: 200!important;
}

.font-weight-300{
  font-weight: 300!important;
}

.font-weight-400{
  font-weight: 400!important;
}

.font-weight-500{
  font-weight: 500!important;
}

.font-weight-600{
  font-weight: 600!important;
}

.font-weight-700{
  font-weight: 700!important;
}

.font-weight-800{
  font-weight: 800!important;
}

.font-weight-900{
  font-weight: 900!important;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--orange);
  border-color: var(--orange);
}

.page-link{
  color: var(--orange);
}

.page-link:hover {
  color: var(--orange);
  text-decoration: none;
  background-color: #efeee9;
  border-color: #efeee9;
}

.cursor-pointer{
  cursor: pointer;
}

@media (max-width: 576px) {
  .breadcrumb{
      margin-top: 63px!important;
  }
}

.text-black{
  color: black;
}

.b-rad-0{
  border-radius: 0;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

@import url('./system.css');
@import url('./feed-grid-system.css');
@import url('./drawer.css');
@import url('./header-index.css');
